import React, {FC} from "react";
import SingleUsePageTemplate from "../pageTemplates/SingleUsePageTemplate";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = () => {

    return <SingleUsePageTemplate primaryBrandLogoUrl={'../images/ds-logo.png'} title="Forgotten Password" columns={1}>
        <h2>Forgot Password</h2>
        <p>To reset your password please enter the email address that your account is associated with.</p>
        <ForgotPasswordForm/>
    </SingleUsePageTemplate>
}

export default ForgotPassword