import React, {useState} from 'react';
import { PickerOverlay } from 'filestack-react';

import Button from "antd/es/button";
import {Field} from "formik";
import Upload from "antd/es/upload";
import { isArray,isString, remove} from 'lodash';
import Icon from "./icon/Icon";

let FILESTACK_API = process.env.REACT_APP_FILESTACK_API


export const fileStackStringToAntUploaderFile = (fileBlobString) =>{

    let fileStackObject = fileBlobString
    if(isString(fileBlobString)){
        fileStackObject = JSON.parse(fileBlobString)
    }
    return fileStackToAntUploaderFile(fileStackObject)
}

export const fileStackToAntUploaderFile = (fileBlob) => {
    // console.log("fileStackToAntUploaderFile",fileBlob)
    return {
        uid: fileBlob.handle,
        name: fileBlob.filename,
        status: 'done',
        url: fileBlob.url
    }
}


const UploadButton = ({icon, onUpload, uploadLabel, actionOptions}) => {

    const [visible, setVisible] = useState(false)


        return <div>

            <Button  shape="round" onClick={()=>setVisible(true)}>
            {icon ? <Icon icon={icon}/> : null} {uploadLabel}
        </Button>
            {visible?<PickerOverlay
                apikey={FILESTACK_API}
                actionOptions={actionOptions}
                onSuccess={(result) => {
                    result.filesUploaded.map((value, index) => {
                        onUpload(JSON.stringify(value));
                        return true
                    })
                }}
                onError={(error) => {
                    console.error('error', error);
                }}
            />:''}
        </div>


};

const FileUploader = ({name,value,maxFiles,uploadLabel,replaceLabel,...props}) => {

    const actionOptions = {
        maxFiles: maxFiles||1,
    };


    return (<Field name={name}>
             {({
                  field,
                  form: {touched, errors, setFieldValue, values}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
              }) => {

                let fileList = [];

                if(value){
                    if(isArray(value)){
                        fileList = value
                    }else{
                        fileList = [value]    
                    }
                }
                
                 const onDelete = (file) => {
                    if(isArray(value)){
                        // console.log("deleting",file)
                           let newList = remove(fileList,(f)=>{return f.handle!==file.uid})
                        //    console.log("deletingnewList",newList)

                           setFieldValue(field.name, newList)

                           
                    }else{
                        setFieldValue(field.name, '');
                    }
                 };

                 const onUpload = (stringJSON) => {
                     let fileObject = JSON.parse(stringJSON)
                    if(maxFiles>1){
                        fileList.push(fileObject)
                        setFieldValue(field.name, fileList)
                    }else{
                        setFieldValue(field.name, fileObject)
                    }
                     
                 };

                 let tempFileList = [];
                 fileList.forEach(file=>{
                    tempFileList.push(fileStackStringToAntUploaderFile(file))
                 })
                 

                //  console.log("fileList",fileList)
                //  console.log("tempFileList",tempFileList)
 
                 return <Upload fileList={tempFileList}  disabled={true} onRemove={(file)=>onDelete(file)}>
                            <UploadButton icon={"cloud-upload"} tabIndex={props.tabIndex}
                                          actionOptions={actionOptions}
                                          onUpload={onUpload}
                                          uploadLabel={uploadLabel}/>
                        </Upload>
            }
        }</Field>)
};



export default FileUploader;
