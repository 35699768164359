import React, {FC, useState} from 'react';
import {Layout, Menu, Drawer, Button, Alert} from 'antd';
import styled from "styled-components";
import InvitationPage from "./InvitationPage";
import { MenuInfo } from 'rc-menu/lib/interface';
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import AccountDashboard from "./AccountDashboard";
import {Application, Company, Contact, Development} from "../data/Application";
import Developments from "./Developments";
import Contacts from "./Contacts";
import ApplicationStart from "./ApplicationStart";
import ApplicationPage from "./Application";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

interface StyleGuideProps {}

const TopTitle = styled.span`
    color: #ffffff;
`

const BackButton = styled(Button)`
    position: absolute;
    top: 10px;
    left: 0px;
`
const PageNoteButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 0px;
`
const FNB_LOGO = "https://upload.wikimedia.org/wikipedia/en/thumb/8/80/First_National_Bank_Logo.svg/1200px-First_National_Bank_Logo.svg.png"
const APPLICATIONS:Array<Application> = [
    { type:"New", bankName:"FNB", bankLogo:FNB_LOGO, developmentName:"Blue Raven", description:"Launch", dateCreated:new Date(),dateSubmitted:undefined, status:"New", loading:false},
    { type:"New Phase", bankName:"FNB", bankLogo:FNB_LOGO, developmentName:"Steenberg", description:"Launch", dateCreated:new Date(),dateSubmitted:new Date(), status:"Submitted", loading:false},
    { type:"Price Change", bankName:"FNB", bankLogo:FNB_LOGO, developmentName:"Waterfall-Hills", description:"Phase 2 Launch", dateCreated:new Date(),dateSubmitted:new Date(), status:"Changes Needed", loading:false},
]
const DEVELOPMENTS:Array<Development> = [
    { name:"Steenberg", applicationCount:1, suburb:"Westlake", municipality:"Western Cape", typeOfUnits:'Full Title Units',description:"Located in the surroundings of Westlake"},
    { name:"Serengeti", applicationCount:2, suburb:"East Rand", municipality:"Guateng", typeOfUnits:'Sectional Title Units',description:"High end golf estate"},
    { name:"Blue Downs",  applicationCount:1,suburb:"Westlake", municipality:"Western Cape", typeOfUnits:'Sectional Title Units',description:"Popular and sought after development to live"},
]

const CONTACTS:Array<Contact|Company> =[
    { name: "Simon Comley", type:'Invividual', companyName:"Cleaver Consulting",landlinePhone:"+27738038046",mobilePhone:"+27738038046",emailAddress:"simon@cleaver.co.za"},
    { name: "Deon Basson", type:'Invividual',companyName:"RED-i",landlinePhone:"+277777777",mobilePhone:"+278888888",emailAddress:"deon@red-i.co.za"},
    { name: "Cleaver Consulting", type:'Company',landlinePhone:"+277777777",mobilePhone:"+278888888",emailAddress:"simon@cleaver.co.za"},
]
const StyleGuide: FC<StyleGuideProps> = () => {

    const [page,setPage] = useState<string|undefined>('account-dashboard')
    const [visible, setVisible] = useState<boolean>(false);

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const handleMenuClick = (e: MenuInfo) =>{
        setPage(e.key.toString())
    }

    let pageComponent
    let pageNotes
    let fullScreen = false
    if(page==='auth-initial-invitation'){
        pageComponent = <InvitationPage mode={'register'}/>
        pageNotes = <p>People sent invitations will end up on this page only if the DS App <strong>does not recognise their email address</strong> then
                            they this page will be used
                        </p>

    }else if(page==='auth-invitation-user-logged-out'){
        pageComponent = <InvitationPage mode={'login'}/>
        pageNotes = <p>People sent invitations <strong>via an email that is recognised by the DS App</strong> end up on this
            page if they  <strong>are currently logged out</strong>
        </p>
    }else if(page==='auth-invitation-user-logged-in'){
        pageComponent = <InvitationPage mode={'connect'}/>
        pageNotes = <p>People sent invitations <strong>via an email that is recognised by the DS App</strong> end up on this
            page if they  <strong>are currently logged in</strong>
        </p>
    }else if(page==='auth-forgot-pwd'){
        pageComponent = <ForgotPassword/>
        pageNotes = <p>A page for users to be able to send a reset password email to themselves.  The lack of bank branding is intentional as it is unknown which bank this user is logging in for</p>
    }else if(page==='auth-reset-pwd'){
        pageComponent = <ResetPassword/>
        pageNotes = <p>A page for users to be able to set a new password.  The lack of bank branding is intentional as it is unknown which bank this user is logging in for</p>
    }else if(page==='auth-sign-in'){
        pageComponent = <Login/>
        pageNotes = <p>A page for users to be able to login. The lack of bank branding is intentional as it is unknown which bank this user is logging in for</p>
    }else if(page==='account-dashboard'){
        // fullScreen = true
        pageComponent = <AccountDashboard applications={APPLICATIONS}/>
        pageNotes = <p>A page for users to view all the applications they have created or been invited to. They can also create a new application directly from inside the system</p>
    }else if(page==='account-developments'){
        // fullScreen = true
        pageComponent = <Developments developments={DEVELOPMENTS}/>
        pageNotes = <p>A page for users to view all the developments they have created or been invited to. They can also create a new development here.</p>
    }else if(page==='account-contacts'){
        // fullScreen = true
        pageComponent = <Contacts contacts={CONTACTS}/>
        pageNotes = <p>A page for users to view all the contacts they have created or been invited to. They can also create a new contact here.</p>
    } else if(page==='application-new-start'){
        // fullScreen = true
        pageComponent = <ApplicationStart mode={"new"}/>
        pageNotes = <p>This is where a user starts a new application for a new development.</p>
    } else if(page==='application-existing-start'){
        // fullScreen = true
        pageComponent = <ApplicationStart mode={"existing"}/>
        pageNotes = <p>This is where a user starts a new application for an existing development.</p>
    }  else if(page==='application-development'){
        // fullScreen = true
        pageComponent = <ApplicationPage step={0} onSave={()=>setPage('application-developer')}/>
        pageNotes = <p>The user defines the development information for this application if this is a new development, this step is not required in the case of a price change or new phase </p>
    }  else if(page==='application-developer'){
        // fullScreen = true
        pageComponent = <ApplicationPage step={1} onSave={()=>setPage('application-builder')}/>
        pageNotes = <p>The user defines the developer information for this application. For an existing developer on and existing development (e.g. price change, new phase) this information will be pre-filled.</p>
    } else if(page==='application-builder'){
        // fullScreen = true
        pageComponent = <ApplicationPage step={2} onSave={()=>setPage('application-application')}/>
        pageNotes = <p>The user defines the builder information for this application. For an existing builder on an existing development (e.g. price change, new phase) this information will be pre-filled.</p>
    }  else if(page==='application-application'){
        // fullScreen = true
        pageComponent = <ApplicationPage step={3} onSave={()=>setPage('application-documents')}/>
        pageNotes = <p>The user fills out the specific information for the actual application around scope of the development, unit counts etc</p>
    }  else if(page==='application-documents'){
        // fullScreen = true
        pageComponent = <ApplicationPage step={4} onSave={()=>setPage('application-review')}/>
        pageNotes = <p>The user uploads the required documents to help support the application</p>
    }  else if(page==='application-review'){
        // fullScreen = true
        pageComponent = <ApplicationPage step={5} onSave={()=>setPage('application-complete')}/>
        pageNotes = <p>The user reviews the application information that they have filled out. Links will be provided to allow them to correct the information</p>
    } else{
        pageComponent = <Alert message={"Sorry. This page has not yet been prototyped"}/>
    }






    return (
        <Layout style={{background: '#F1F2F7', height:"100vh"}}>
            {!fullScreen?<Header className="header">
                <TopTitle>Page Guide</TopTitle>
            </Header>:""}
            <Layout style={{background: '#F1F2F7',height: "calc( 100vh - 64px)", overflow:"hidden"}}>
                {!fullScreen?<Sider width={200} style={{height: "calc( 100vh - 64px)", overflow:"scroll",background: '#FFF',}} >
                    <Menu
                        mode="inline"
                        onClick={handleMenuClick}
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['auth','account','application','user']}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                         <SubMenu key="auth"  title="Authentication">
                            <Menu.Item key="auth-initial-invitation">Invitation 1</Menu.Item>
                            <Menu.Item key="auth-invitation-user-logged-out">Invitation 2</Menu.Item>
                            <Menu.Item key="auth-invitation-user-logged-in">Invitation 3</Menu.Item>
                            <Menu.Item key="auth-forgot-pwd">Forgot Password</Menu.Item>
                            <Menu.Item key="auth-reset-pwd">Reset Password</Menu.Item>
                            <Menu.Item key="auth-sign-in">Sign In</Menu.Item>
                        </SubMenu>
                        <SubMenu key="account"  title="Account">
                            <Menu.Item key="account-dashboard">Dashboard</Menu.Item>
                            <Menu.Item key="account-developments">Developments</Menu.Item>
                            <Menu.Item key="account-contacts">Contacts</Menu.Item>
                            <Menu.Item key="account-users">Users</Menu.Item>
                            {/*<Menu.Item key="account-settings">Settings</Menu.Item>*/}
                        </SubMenu>
                        <SubMenu key="application"  title="Application">
                            <Menu.Item key="application-new-start">New Start</Menu.Item>
                            <Menu.Item key="application-existing-start">Existing Start</Menu.Item>
                            <Menu.Item key="application-development">1. Development</Menu.Item>
                            <Menu.Item key="application-developer">2. Developer</Menu.Item>
                            <Menu.Item key="application-builder">3. Builder</Menu.Item>
                            <Menu.Item key="application-application">4. Application</Menu.Item>
                            <Menu.Item key="application-documents">5. Documents</Menu.Item>
                            <Menu.Item key="application-review">6. Review</Menu.Item>
                            <Menu.Item key="application-complete">7. Complete</Menu.Item>
                        </SubMenu>
                        <SubMenu key="user"  title="User">
                            <Menu.Item key="user-profile">Profile</Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>:""}
                <Layout style={{ padding: '0 24px 24px',background: '#F1F2F7',height:'100vh',overflow:'scroll' }}>
                    <Content
                        style={{
                            position:"relative",
                            padding: 24,
                            margin: 0,
                            minHeight: "auto",
                            background: '#F1F2F7'
                        }}
                    >
                        {fullScreen?<BackButton type="dashed" shape="round" onClick={()=>setPage(undefined)}>Back</BackButton>:''}
                        {pageNotes?<PageNoteButton type="dashed" shape="round" onClick={()=>setVisible(true)}>Page Notes</PageNoteButton>:''}
                        {pageComponent}
                        <Drawer
                            title="Page Notes"
                            placement="right"
                            closable={false}
                            onClose={onClose}
                            visible={visible}
                        >
                            {pageNotes}
                            <Alert message={"Please note: All of these pages are mocked and do not work, please do not expect any validation, navigation or mobile friendliness"}/>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default StyleGuide;