import React, {FC} from "react";
import {Formik} from "formik";
import {Button} from "antd";
import {EnumSelect, MainForm, RadioButtons, StringInput} from "./FormElements";

interface ApplicationExistingFormProps {}
interface ApplicationExistingFormInitialValues {

}
const ApplicationExistingForm: FC<ApplicationExistingFormProps> = () => {
    const initialValues: ApplicationExistingFormInitialValues = {  };

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            console.log({ values, actions });
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
        }}
        render={() => (
           <MainForm>

                <EnumSelect code="development" list={["exampledev1","exampledev2"]}/>
                <EnumSelect code="applicationType" list={["price-update","new-phase"]}/>

                <StringInput code="applicationReason" />
                <RadioButtons code="bank" list={["fnb"]}/>
               <StringInput code="vendorCode" />

                <Button type={"primary"} size={"large"} >Start Application</Button>
           </MainForm>)}/>
}

export default ApplicationExistingForm