import React, {FC} from "react";
import {Formik} from "formik";
import {Form, Input} from "formik-antd";
import {Button} from "antd";

interface LoginFormProps {
    primaryActionText?: string;
}
interface LoginFormInitialValues {
    emailAddress: string
    password: string,
}
const LoginForm: FC<LoginFormProps> = ({primaryActionText='Sign in'}) => {
    const initialValues: LoginFormInitialValues = { emailAddress:'',password:''};

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            console.log({ values, actions });
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
        }}
        render={() => (
            <Form layout={'vertical'}>
                <Form.Item name='emailAddress' label={"Email Address"}>
                    <Input name='emailAddress' placeholder='name@domain.com' />
                </Form.Item>

                <Form.Item name='password' label={"Password"}>
                    <Input.Password  name='password' placeholder='' />
                </Form.Item>


                <Button type={"primary"} size={"large"} block>{primaryActionText}</Button>
                <Form.Item name="forgot">
                    <a className="login-form-forgot" href="">
                        Forgot password?
                    </a>
                </Form.Item>
            </Form>)}/>
}

export default LoginForm