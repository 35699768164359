import React, {useEffect, useState, useCallback, FC} from 'react';
import {Form, Select, Radio, Checkbox} from 'formik-antd';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {FastField, FieldArray} from "formik";
import {useDebouncedCallback} from "use-debounce";
import {Button, Col, Empty, Input as AntInput,Row} from 'antd';
import {fetchLocation, Location} from "../services/LocationService";
import Icon from "../components/icon/Icon";
import FileUploader from "../components/FileUploader";
import ContactPickerModal from "../modals/ContactPickerModal";
import ContactCard from "../components/ContactCard";
import Title from "antd/es/typography/Title";


const {Item} = Form;
const {Option} = Select;

// FORM
// ====
const StyledForm = styled(Form)`
  //max-width: 800px;
  
  label {
    font-weight: bold;
  }
`
export const MainForm = ({children, ...props}) => {
    return <StyledForm layout="vertical" {...props}>
        {children}
    </StyledForm>
}

// FORM SECTION
// ====
const StyledFormSection = styled.div`
    margin-bottom: 3em;
  h4{
    opacity: 0.7;
    margin-bottom: 0;
  }
  p{
    font-style: italic;
  }
`
const SectionHeader = styled.div`
    margin-bottom: 1em;
`
declare const titleLevels: [1, 2, 3, 4, 5];
interface FormSectionProps {
    title:string
    level?: typeof titleLevels[number]
    description?:string
}

export const FormSection: FC<FormSectionProps> = ({title,level=4,description, children, ...props})=>{
    return <StyledFormSection {...props}>
        <SectionHeader>
            <Title level={level}>{title}</Title>
            {description?<p>{description}</p>:''}
        </SectionHeader>

        {children}
    </StyledFormSection>
}


// FORM LABEL
// ====
interface FormLabelProps {
    label?:any
    code?:any
    name?:any
}
const FormLabel: FC<FormLabelProps> = ({label,code,name})=>{
    const {t} = useTranslation()

    if(label===''){ // if explicitly set
        return ''
    }
    return label || t(`${code}.label`) ||  t(`${name}.label`)
}

// FORM ITEM
// ====
interface FormItemProps {
    code: string,
    label?: string,
    extra?: string,
    hideLabel?: boolean,
}

const StyledItem = styled(Item)`
  margin-bottom: 1em;
`
export const FormItem: FC<FormItemProps> = React.memo(({code, label, extra, hideLabel, ...props}) => {
    const {t} = useTranslation();

    let formLabel = <FormLabel label={label} code={code}/>;

    return <Item label={hideLabel ? null : formLabel} name={code} extra={extra || t(`${code}.extra`,'')}>
      {props.children}
    </Item>
})

// FORM ITEM Container
// ====
interface FormItemContainerProps {
    code: string,
    label?: string,
    extra?: string,
}

export const FormItemContainer: FC<FormItemContainerProps> = React.memo(({code, label, extra, ...props}) => {
    const {t} = useTranslation();

    return <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
                <label>{<FormLabel label={label} code={code}/>}</label>
            </div>
            <div className="ant-col ant-form-item-control">
                {props.children}
            </div>
    </div>
})


// UTILS
// ====
const INPUT_DELAY = 400;
export const withDebounce = BaseComponent => ({name, code, ...props}) => {
    const {t} = useTranslation();

    const [innerValue, setInnerValue] = useState('');

    useEffect(() => {
        if (props.value) {
            setInnerValue(props.value);
        } else {
            setInnerValue('');
        }
    }, [props.value]);

    const [debouncedHandleOnChange] = useDebouncedCallback(
        (event) => {

            if (props.onChange) {
                props.onChange(event);
            }
        },
        INPUT_DELAY
    );

    const handleOnChange = useCallback((event) => {
        event.persist();

        const newValue = event.currentTarget.value;
        setInnerValue(newValue);
        debouncedHandleOnChange(event);
    }, [debouncedHandleOnChange]);

    return <BaseComponent name={name || code} code={code} t={t} placeholder={t(`${code}.placeholder`)}
                          value={innerValue} onChange={handleOnChange}/>
}

// INPUTS
// ====
export const PlainStringInput = ({code, name, value, handleOnChange, t, ...props}) => {
    return <AntInput name={name || code} placeholder={t(`${code}.placeholder`)} value={value}
                     onChange={handleOnChange} {...props}/>
}
export const DebouncedStringInput = withDebounce(PlainStringInput)

interface StringInputProps {
    code: string
    name?: string
    label?: string
    hideLabel?: boolean
}

export const StringInputField: FC<StringInputProps> = React.memo(({code, name, label,hideLabel, ...props}) => {
    return   <FastField name={name || code}>
        {({
              field, // { name, value, onChange, onBlur }
              form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              meta,
          }) => (
            <div>
                <DebouncedTextInput name={name} code={code} {...field} />
                {meta.touched && meta.error && (
                    <div className="error">{meta.error}</div>
                )}
            </div>
        )}
    </FastField>
})
export const StringInput: FC<StringInputProps> = React.memo(({code, name, label,hideLabel, ...props}) => {

    return <FormItem code={code}  label={label} hideLabel={hideLabel}>
                <StringInputField code={code} name={name}/>
            </FormItem>
})


export const PlainTextArea = ({code,name,value,handleOnChange,t,...props}) => {
    return <AntInput.TextArea  name={name||code}  placeholder={t(`${code}.placeholder`)}  value={value} onChange={handleOnChange} {...props}/>
}
export const DebouncedTextArea =  withDebounce(PlainTextArea)

export const PlainTextInput = ({code,name,value,handleOnChange,t,...props}) => {
    return <AntInput   name={name||code}  placeholder={t(`${code}.placeholder`)}  value={value} onChange={handleOnChange} {...props}/>
}
export const DebouncedTextInput =  withDebounce(PlainTextInput)

interface TextAreaInputProps {
    code: string
    name?: string
}
export const TextAreaInput: FC<TextAreaInputProps>  = React.memo(({code,name,...props}) => {

    return <FormItem code={code} >
        <FastField name={name||code}>

            {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
              }) => (
                <div>
                    <DebouncedTextArea name={name} code={code} {...field} />
                    {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                    )}
                </div>
            )}
        </FastField>
    </FormItem>
})



interface EnumSelectProps {
    code: string
    name?: string
    label?: string
    list: Array<string>
    hideLabel?: boolean
}

export const EnumSelect: FC<EnumSelectProps> = React.memo(({code,name,label,list,hideLabel,...props}) => {
    const {t} = useTranslation();

    const options = list.map(item=>{
        return <Option key={item} value={item}>{t(`${code}.${item}`)}</Option>
    })

    return <FormItem code={code} label={label} hideLabel={hideLabel}>
        <Select name={name||code} fast placeholder={t(`${code}.placeholder`)} >
            {options}
        </Select>
    </FormItem>
})

// RADIO BUTTONS
// ====
interface RadioButtonsProps {
    code: string
    list: Array<string>
}

export const RadioButtons: FC<RadioButtonsProps> = ({code,list,...props}) =>{

    const {t} = useTranslation()

    const options = list.map(item=>{
        return <Radio.Button key={item}>{t(`${code}.${item}`)}</Radio.Button>
    })

    return <FormItem code={code}>
        <Radio.Group name={code}>
            {options}
        </Radio.Group>
    </FormItem>
}


// RADIO GROUP
// ====
interface RadioGroupInputProps {
    code: string
    list: Array<string>
}
interface RadioOption {
    label: string
    value: string
}

export const RadioGroupInput: FC<RadioGroupInputProps> = ({code,list,...props}) =>{

    const {t} = useTranslation()

    const options: Array<CheckBoxOption> = []
    list.forEach(item=>{
        options.push({ label: t(code+'.'+item), value: item  })
    })


    return <FormItem code={code}>
        <Radio.Group name={code} options={options}/>
    </FormItem>
}

// BOOLEAN BUTTONS
// ====
interface BooleanButtonsProps {
    code: string
}

export const BooleanButtons: FC<BooleanButtonsProps> = ({code,...props}) =>{

    const {t} = useTranslation()

    const options = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
    ];

    return <FormItem code={code}>
        <Radio.Group name={code} options={options}/>
    </FormItem>
}

// CHECKBOX GROUP
// ====
interface CheckBoxGroupInputProps {
    code: string
    list: Array<string>
}
interface CheckBoxOption {
    label: string
    value: string
}

export const CheckBoxGroupInput: FC<CheckBoxGroupInputProps> = ({code,list,...props}) =>{

    const {t} = useTranslation()

    const options: Array<CheckBoxOption> = []
    list.forEach(item=>{
            options.push({ label: t(code+'.'+item), value: item  })
    })


    return <FormItem code={code}>
            <Checkbox.Group name={code} options={options}/>
           </FormItem>
}


// LOCATION SEARCH
// ===
const MultipleSelectBox = styled(Select)`
    max-width: 320px;
    @media only screen and (min-width: 970px) {
        max-width: 100%;
    } 
`


interface LocationSearchProps {
    code: string,
    name?: string
}

export const LocationSearch: FC<LocationSearchProps> = ({code,name}) => {

    const [data, setData] = useState<Array<Location>>([])

    const options = data.map(d => <Option key={d.value} value={d.value}>{d.text}</Option>);

    const handleSearch = value => {
        if (value) {
            fetchLocation(value, data => setData(data));
        } else {
            setData([]);
        }
    };
    return <FormItem code={code}>
                <MultipleSelectBox name={name||code}
                                      style={{ width: "100%" }}
                                      placeholder="e.g. Bryanston"
                                      onSearch={handleSearch}
                                      notFoundContent="Start typing to search the suburbs you are interested in"
                               showSearch
                               defaultActiveFirstOption={false}
                               showArrow={false}
                               filterOption={false}
            >
                {options}
            </MultipleSelectBox>
    </FormItem>
}


// Field Array
// ===

export const  getExplodedValues = (values,code) =>{
    const valuePathItems = code.split(".")
    let returnValue = {}
    let loopValues = values
    valuePathItems.forEach(path=>{
        returnValue = loopValues[path]
        loopValues = returnValue
    })
    return returnValue

}
export const StyledFieldArrayItem = styled.div`
    background: whitesmoke;
    position:relative;
    padding:10px;
    border-radius: 5px;
    margin-bottom: 1em;
    .ant-form-item{
        margin-bottom:0;
    }
`

export const FormFieldArray = ({code,values,renderItem,emptyData,...props}) =>{

    return <FormItem code={code}>
                <FieldArray name={code} render={arrayHelpers=>{

                    let valueMap = getExplodedValues(values,code)
                    console.log("valueMap",valueMap)
                    return <div>
                        {valueMap && Object.keys(valueMap).length > 0 ? (
                            Object.keys(valueMap).map((itemKey, index) => {
                                let item = valueMap[itemKey]
                                return <StyledFieldArrayItem key={index}>
                                    <Row wrap={false}>

                                        <Col flex="auto">{renderItem(code,`${code}.${index}`,item, index)}</Col>
                                        <Col flex="40px">
                                            <Button style={{float:'right'}} icon shape="circle"
                                                    onClick={() => arrayHelpers.remove(index)} >
                                                <Icon icon="bin"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </StyledFieldArrayItem>
                            })
                        ) : ""}
                        <Button shape="round" onClick={() => arrayHelpers.push(emptyData)}>

                            <Icon icon="plus"/> Add
                        </Button>
                    </div>

                }}/>
    </FormItem>
}


// FILE UPLOAD
// ====

interface UploadExample {
    url: string
    title: string
}
interface UploadInputProps {
    code: string,
    name?: string
    maxFiles?: number
    example?: UploadExample
    value?: string
}

export const getUrlFromExampleFile = (example)=>{

    if(!example){
        return null
    }

    let url  = example.file
    if(example.file && example.file[0]==="{"){
        const json = JSON.parse(example.file)
        url = json.url
    }
    return url

}

export const FormItemRow = styled.div`
      margin-left: 12px;
      margin-right: 12px;
    width: 100%;
    background: #ffffff;
    position:relative;
    padding:10px;
    border-radius: 5px;
    margin-bottom: 1em;
    .indicator {
       text-align: center;
      vertical-align: middle;
      &.indicator--bad{
        color: grey;
      }
      &.indicator--good{
        color: green;
      }
    }
    h3{
      font-size: 1em;
      margin-bottom: 0;
      vertical-align: middle;
    }
`

export const UploadInputRow: FC<UploadInputProps>  = React.memo(({code,name,maxFiles=1,example,value,...props}) => {

    let sampleDownloadBtn
    if(example){
        sampleDownloadBtn = <Button type="link" shape="round">
            <a href={example.url} target="_blank" rel="noopener noreferrer" >
                <Icon icon="cloud-download"/> {example.title}
            </a>
        </Button>
    }

    return <FormItemRow style={{backgroundColor:"whitesmoke"}}>
        <FastField name={name || code}>
            {({
                  field, // { name, value, onChange, onBlur }
                  form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
              }) => (
                <Row gutter={16} align="middle">
                    <Col flex={"40px"}>
                        <div className={value?"indicator indicator--good":"indicator indicator--bad"}>
                            <Icon icon={value?"checkmark":"cross"}/>
                        </div>
                    </Col>
                    <Col flex="auto">
                        <h3><FormLabel code={code}/></h3>
                        {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                        )}
                    </Col>
                    <Col flex={"100px"}>{sampleDownloadBtn}</Col>
                    <Col flex={"100px"}>
                        <FileUploader name={name||code} uploadLabel="Upload" replaceLabel="Re-upload" maxFiles={maxFiles} value={value}/>
                    </Col>
                </Row>
            )}
        </FastField>
    </FormItemRow>

})

// CONTACT INPUT
// ====

interface ContactInputProps {
    code: string
    name?: string
}


export const ContactInput: FC<ContactInputProps> = ({code, name})=>{

    return <div>
        <FastField name={name || code}>
            {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
              }) => {
                const { name, value, onChange, onBlur } = field


                if(!value){
                    return  <Empty description={"Select contact"} style={{margin:0,background:"#FFF", padding: "2em 1em", border: "1px dashed rgba(0, 0, 0, 0.16)"}}
                                   imageStyle={{
                                       height: 40,
                                   }}>
                        <ContactPickerModal onSelect={(company,contact)=>{
                            console.log(company,contact)
                            contact.company = company
                            form.setFieldValue(code,contact)
                        }}
                            trigger={(onClick) => {
                            return <Button type="primary" shape={"round"} onClick={onClick}>Add</Button>
                        }}
                        />
                    </Empty>
                }
                return <ContactCard contact={value} company={value.company} handleChange={(company,contact)=>{
                    console.log(company,contact)
                    contact.company = company
                    form.setFieldValue(code,contact)
                }}/>
            }}
        </FastField>

    </div>
}

