import React, {FC} from "react";
import SingleUsePageTemplate from "../pageTemplates/SingleUsePageTemplate";
import ResetPasswordForm from "../forms/ResetPasswordForm";

interface ResetPasswordProps {}

const ResetPassword: FC<ResetPasswordProps> = () => {

    return <SingleUsePageTemplate primaryBrandLogoUrl={'../images/ds-logo.png'} title="Forgotten Password" columns={1}>
        <h2>Reset Your Password</h2>
        <p>Please enter in a new password</p>
        <ResetPasswordForm/>
    </SingleUsePageTemplate>
}

export default ResetPassword