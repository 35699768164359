import React, {FC} from "react";
import {Formik} from "formik";
import {Button, Col, Row} from "antd";
import {
    CheckBoxGroupInput,
    EnumSelect,
    FormFieldArray, FormItemContainer,
    MainForm,
    StringInput,
    TextAreaInput, UploadInputRow
} from "./FormElements";
import {useTranslation} from "react-i18next";

interface ContactCreateFormProps {
    onSave():any
}
interface ContactCreateFormInitialValues {
    companyName: string,
    companyType: string,
    postalAddress: string,
    physicalAddress?: string,
    contactName: string,
    contactEmailAddress: string,
    contactMobileNumber: string,
    contactLandlineNumber: string,
}



const ContactCreateForm: FC<ContactCreateFormProps> = ({onSave}) => {
    const initialValues: ContactCreateFormInitialValues = {
        companyName:'',
        companyType:'',
        postalAddress:'',
        physicalAddress:'',
        contactName:'',
        contactEmailAddress:'',
        contactMobileNumber:'',
        contactLandlineNumber:''
    };
    const {t} = useTranslation()

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            // console.log({ values, actions });
            onSave()
            actions.setSubmitting(false);
        }}
        render={({values}) => {
           return <MainForm>
               <StringInput code="companyName"/>
               <CheckBoxGroupInput code="companyType" list={["mortgage-originator","transfer-attorney","bond-attorney"]}/>
               <Row gutter={16}>
                   <Col span={12}>
                       <TextAreaInput code="postalAddress"/>
                   </Col>
                   <Col span={12}>
                       <TextAreaInput code="physicalAddress"/>
                   </Col>
               </Row>
               <StringInput code="contactName"/>
               <Row gutter={16}>
                   <Col span={8}>
                       <StringInput code="contactEmailAddress"/>
                   </Col>
                   <Col span={8}>
                       <StringInput code="contactMobileNumber"/>
                   </Col>
                   <Col span={8}>
                       <StringInput code="contactLandlineNumber"/>
                   </Col>
               </Row>
               <Button  type={"primary"} size={"large"}>
                   Save
               </Button>
           </MainForm>
        }}/>
}

export default ContactCreateForm