import React, {FC} from "react";
import styled from "styled-components";


const ListItemContainer = styled.div`
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 3px;
  background: #ffffff;
  
  
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  @media (min-width: 800px) {
    display:flex;
    align-items:center;
    justify-content: space-between;
  }
`
const ListItemContent = styled.div`
  padding: 0;
  margin-bottom: 1em;


  @media (min-width: 800px) {
    margin-bottom: 0em;

  }
`
const ListItemActions = styled.div`
  display:block;
  .ant-btn{
    display:block;
    width: 100%;
  }

  @media (min-width: 800px) {
    display:inline-block;
    float: right;
  }

`

export const ContentCol = styled.div`
  padding: 0 1em 1em 0;
  flex: 1;
  justify-content: flex-start;
  //float:left;
  //width: 20%;
`

interface ListItemProps {
    render: React.ReactNode
    actions: React.ReactNode
}

const ListItem: FC<ListItemProps>= ({render,actions}) =>{
    return <ListItemContainer>
            <ListItemContent>{render}</ListItemContent>
            <ListItemActions>{actions}</ListItemActions>
        </ListItemContainer>
}

export default ListItem