import React, {FC} from 'react';
import styled from 'styled-components';
import {FormSection, MainForm, UploadInputRow} from "../forms/FormElements";
import {Alert, Button, Col, Descriptions, List, Row, Table} from "antd";
import {DATE_FORMAT_DATE_ONLY, SimpleDate} from "./Display";
import ContactCard from "./ContactCard";
import {StyledApplicationContainer} from "../pages/Application";
import {DOCUMENT_CATEGORIES, DOCUMENTS} from "../forms/DocumentationForm";
import {useTranslation} from "react-i18next";
import Icon from "./icon/Icon";

const { Column, ColumnGroup } = Table;


const StyledDescriptions = styled(Descriptions)`
  margin-bottom: 2em;
  .ant-descriptions-item-content{
    background: whitesmoke;
  }
  .ant-descriptions-item{
    padding-top: 0.5em;
    padding-bottom: 0.1em;
    
  }
  .ant-descriptions-item-label{
    font-weight: bold;
  }
`

interface ApplicationSummaryProps{

}


const currentPhaseData = [
    {
        key: '1',
        phase: 'Phase 1',
        fullTitleUnits: 100,
        fullTitleDate: new Date(),
        sectionalTitleUnits: 100,
        sectionalTitleDate: new Date(),
    },
    {
        key: '2',
        phase: 'Phase 2',
        fullTitleUnits: 100,
        fullTitleDate: new Date(),
        sectionalTitleUnits: 100,
        sectionalTitleDate: undefined,
    },
];


const developmentHistoryData = [
    {
        key: '1',
        development: 'My Previous Estate',
        state: 'In Progress',
        area: 'Parklands',
        totalUnits: 100,
        dateCompleted: '',
    },
    {
        key: '2',
        development: 'My Other Estate',
        state: 'Completed',
        area: 'Bergvliet',
        totalUnits: 1000,
        dateCompleted: '23/04/2020',
    },
];

const developmentHistoryColumns = [
    {
        title: 'Development',
        dataIndex: 'development',
        key: 'development',
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
    },
    {
        title: 'Area',
        dataIndex: 'area',
        key: 'area',
    },
    {
        title: 'Total Units',
        dataIndex: 'totalUnits',
        key: 'totalUnits',
    },
    {
        title: 'Date Completed',
        dataIndex: 'dateCompleted',
        key: 'dateCompleted',
    },
];

const ApplicationSummary: FC<ApplicationSummaryProps> = () => {
    const {t} = useTranslation()

    return <StyledApplicationContainer>
        <Row gutter={16}>
           <Col span={16}>
               <FormSection title={"Application"}>
                   <StyledDescriptions size={"small"}  title="" colon={false} layout="vertical" bordered  column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}>
                       <Descriptions.Item label="Suburb">Parklands</Descriptions.Item>
                       <Descriptions.Item label="City">Cape Town</Descriptions.Item>
                       <Descriptions.Item label="Province">Western Cape</Descriptions.Item>
                       <Descriptions.Item label="Municipality">Blouberg</Descriptions.Item>
                   </StyledDescriptions>
                   <StyledDescriptions size={"small"}  title="" colon={false} layout="vertical" bordered column={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                       <Descriptions.Item label="Type of loan required">Building Loan (Plot & Plan)</Descriptions.Item>
                       <Descriptions.Item label="Tripartate Agreement Required">Yes</Descriptions.Item>
                       <Descriptions.Item label="Description" span={2}>
                          This is a wonderful development, pure Tuscan housing throughout with terracotta tiles wall to wall. It's like living in Italy but not...
                       </Descriptions.Item>
                   </StyledDescriptions>
                   <StyledDescriptions size={"small"}  title="" colon={false} layout="vertical" bordered  column={{ md: 3, sm: 2, xs: 1 }}>
                       <Descriptions.Item label="Units in Development">100</Descriptions.Item>
                       <Descriptions.Item label="Units in Current Phase">83</Descriptions.Item>
                       <Descriptions.Item label="Units Allocated to You">83</Descriptions.Item>
                   </StyledDescriptions>

                   <Table  title={() => 'Current Phase Data'} dataSource={currentPhaseData}  size="small" pagination={false} bordered>
                       <Column title="Phase" dataIndex="phase" key="phase" />
                       <ColumnGroup title="Full Title Units">
                           <Column title="Units" dataIndex="fullTitleUnits" key="fullTitleUnits" />
                           <Column title="Delivery Date" dataIndex="fullTitleDate" key="fullTitleDate" render={date => (<SimpleDate date={date} format={DATE_FORMAT_DATE_ONLY}/>)} />
                       </ColumnGroup>
                       <ColumnGroup title="Sectional Title Units">
                           <Column title="Units" dataIndex="sectionalTitleUnits" key="sectionalTitleUnits" />
                           <Column title="Delivery Date" dataIndex="sectionalTitleDate" key="sectionalTitleDate"  render={date => (<SimpleDate date={date} format={DATE_FORMAT_DATE_ONLY}/>)} />
                       </ColumnGroup>
                   </Table>
               </FormSection>
               <FormSection title={"Developer Details"}>
                   <StyledDescriptions size={"small"} title="" colon={false} layout="vertical" bordered  column={{ md: 4, sm: 2, xs: 1 }}>
                       <Descriptions.Item label="Company Name" span={2}>African Kingdom Holdings Pty Ltd</Descriptions.Item>
                       <Descriptions.Item label="Registration Number">2003/000548896/07</Descriptions.Item>
                       <Descriptions.Item label="NHBRC">9788977</Descriptions.Item>
                       <Descriptions.Item label="Directors" span={4}>David Williams Downes & David Patric Nagle</Descriptions.Item>

                       <Descriptions.Item label="Physical Address" span={2}>1 Jack Nickelous Drive, Serengeti Estate nr R21 and R25 Witfontein</Descriptions.Item>
                       <Descriptions.Item label="Postal Address" span={2}>PO Box Serengetti Esate, Witfontein, 1642</Descriptions.Item>
                   </StyledDescriptions>

                   <Table  title={() => 'Development History'} dataSource={developmentHistoryData} columns={developmentHistoryColumns} size="small" pagination={false} bordered />

               </FormSection>
               <FormSection title={"Builder Details"}>
                   <StyledDescriptions size={"small"} title="" colon={false} layout="vertical" bordered  column={{ md: 4, sm: 2, xs: 1 }}>
                       <Descriptions.Item label="Company Name" span={2}>African Kingdom Holdings Pty Ltd</Descriptions.Item>
                       <Descriptions.Item label="Registration Number">2003/000548896/07</Descriptions.Item>
                       <Descriptions.Item label="NHBRC">9788977</Descriptions.Item>
                       <Descriptions.Item label="Directors" span={4}>David Williams Downes & David Patric Nagle</Descriptions.Item>

                       <Descriptions.Item label="Physical Address" span={2}>1 Jack Nickelous Drive, Serengeti Estate nr R21 and R25 Witfontein</Descriptions.Item>
                       <Descriptions.Item label="Postal Address" span={2}>PO Box Serengetti Esate, Witfontein, 1642</Descriptions.Item>
                   </StyledDescriptions>
               </FormSection>
               <FormSection title={"Sales Force"}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <ContactCard contact={{id:"X",name:"Agent X", emailAddress:"agentx@agency.com", mobileNumber:"0738000000000", landlineNumber:"0210000000000"}}/>
                        </Col>
                        <Col span={12}>
                            <ContactCard contact={{id:"Y",name:"Agent Y", emailAddress:"agenty@agency.com", mobileNumber:"07380000001111", landlineNumber:"021000000111"}}  />
                        </Col>
                    </Row>
               </FormSection>
               <FormSection title={"Attorneys"}>
                   <Row gutter={16}>
                       <Col span={12}>
                           <ContactCard contact={{id:"A",name:"Transfer Attorney A", emailAddress:"attorneyA@attorney.com", mobileNumber:"073800055555", landlineNumber:"0210000077777"}}/>
                       </Col>
                       <Col span={12}>
                           <ContactCard contact={{id:"B",name:"Bond Attorney B", emailAddress:"attorneyB@attorney.com", mobileNumber:"0738000666666", landlineNumber:"021000666666ß"}}  />
                       </Col>
                   </Row>
               </FormSection>
               <FormSection title={"Supporting Documentation"}>
                   {DOCUMENT_CATEGORIES.map((documentCategory)=>{

                       const docsInCategory = DOCUMENTS.filter((doc)=>(doc.category===documentCategory))
                       docsInCategory.sort((doc)=>doc.position)

                       return  <FormSection title={t(documentCategory)} level={5}>
                           <List itemLayout="horizontal"
                               dataSource={docsInCategory}
                               renderItem={docRequirement => (
                                   <List.Item
                                       actions={[<a href="#"><Icon icon="cloud-download"/></a>]}
                                   >
                                           <List.Item.Meta
                                               title={<a href="#">{docRequirement.label}</a>}
                                           />
                                   </List.Item>
                               )}
                           />
                       </FormSection>
                   })}
               </FormSection>
           </Col>
           <Col span={8}>
                <img src={"https://upload.wikimedia.org/wikipedia/en/thumb/8/80/First_National_Bank_Logo.svg/1200px-First_National_Bank_Logo.svg.png"} style={{width:"100%", padding:30}} />
               <Alert type={"info"} message={"Congratulations!"} style={{marginBottom:"2em"}} description={"You are about to submit this application to FNB, before you do please verify that you are happy with the information you have provided"}/>

               <Button size={"large"} type={"primary"} block >Submit Application</Button>
           </Col>
        </Row>

    </StyledApplicationContainer>
}

export default ApplicationSummary
