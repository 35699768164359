import 'antd/dist/antd.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import StyleGuide from "./pages/StyleGuide";
import  { BreakpointProvider } from 'react-socks';
import './i18n';

function App() {
  return (
      <BreakpointProvider>
          <BrowserRouter>
              <Switch>
                  <Route path="/style-guide" exact component={StyleGuide}/>
              </Switch>
          </BrowserRouter>
      </BreakpointProvider>

  );
}

export default App;
