import React, {FC} from "react";
import {Formik} from "formik";
import {Form, Input} from "formik-antd";
import {Button} from "antd";

interface ForgotPasswordFormProps {
}
interface ForgotPasswordFormInitialValues {
    emailAddress: string
}
const ForgotPasswordForm: FC<ForgotPasswordFormProps> = () => {
    const initialValues: ForgotPasswordFormInitialValues = { emailAddress:''};

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            console.log({ values, actions });
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
        }}
        render={() => (
            <Form layout={'vertical'}>
                <Form.Item name='emailAddress' label={"Email Address"}>
                    <Input name='emailAddress' placeholder='name@domain.com' />
                </Form.Item>


                <Button type={"primary"} size={"large"}  block>Send me a password reset email</Button>

            </Form>)}/>
}

export default ForgotPasswordForm