import React, {FC} from 'react';
import {Col, Row} from "antd";
import styled from "styled-components";




const InfoWindowContent = styled.div`
  background: #fff;
  padding: 1em;
  h2{
    font-size: 1.2em;
  }
`

interface InfoWindowProps {
    title: string,
    description: any,
}

export const InfoWindow: FC<InfoWindowProps> = ({title,description})=>{
    return <InfoWindowContent>
        <h2>{title}</h2>
        <p>{description}</p>
    </InfoWindowContent>
}


const SingleUseContainer = styled.div`
    
`

const SingleUseBranding = styled.div`
  padding: 2em 0;
  border-radius: 3px;
`
const CoBranding = styled.div`
  //padding: 0 2em;
  width:100%;
  overflow: hidden;
  .primary{
    float:left;
    img{
      width: 150px;
      display: block;

    }
    
  }
  .secondary{
    float:right;
    img{
      width: 80px;
      display: block;
    }
  }
`
const SingleBranding = styled.div`
  padding: 0;
  img{
    //margin: 0 auto;
    width: 80px;
    display: block;
  }
`

const SingleUseContent = styled.div`
  background: #fff;
  padding: 2em;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`


interface SingleUsePageTemplateProps {
    title: string,
    columns?: number,
    primaryBrandLogoUrl?: string,
    secondaryBrandLogoUrl?: string,
}
const SingleUsePageTemplate: FC<SingleUsePageTemplateProps> = ({title='',columns=2,primaryBrandLogoUrl,secondaryBrandLogoUrl,children}) => {


    let span = columns === 2 ? 12 : 8
    let offset = columns === 2 ? 6 : 8


    return <SingleUseContainer>
        <Row>
            <Col span={span} offset={offset}>
                {primaryBrandLogoUrl || secondaryBrandLogoUrl ?
                    <SingleUseBranding>
                        {columns===1?
                            <SingleBranding>{primaryBrandLogoUrl?<img src={primaryBrandLogoUrl} />:""}</SingleBranding>
                            :
                            <CoBranding>
                                <div className="primary">
                                    {primaryBrandLogoUrl?<img src={primaryBrandLogoUrl} />:""}
                                </div>
                                <div className="secondary">
                                    {secondaryBrandLogoUrl?<img src={secondaryBrandLogoUrl}/>:""}
                                </div>

                            </CoBranding>
                        }
                    </SingleUseBranding>
                :""}
                <SingleUseContent>
                    {children}
                </SingleUseContent>
            </Col>
        </Row>

    </SingleUseContainer>
}

export default SingleUsePageTemplate