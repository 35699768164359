import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "applicationReason.label":"Reason for Application",
            "applicationReason.placeholder":"Tell us why you are applying",
            "applicationType.label":"Application Type",
            "applicationType.placeholder":"Select the type of application",
            "applicationSubmission.label":"How will you submit application to us?",
            "applicationSubmission.placeholder":"Select one...",
            "applicationSubmission.developer":"Developer",
            "applicationSubmission.originator":"Originator/Agent",
            "bank.label":"Select bank",
            "bank.extra":"Currently only FNB applications are supported",
            "bank.fnb":"FNB",
            "contacts.label":"Contacts",
            "contactName.label":"Contact Name",
            "contactName.placeholder":"Full name",
            "contactEmailAddress.label":"Email Address",
            "contactEmailAddress.placeholder":"name@domain.com",
            "contactMobileNumber.label":"Mobile Number",
            "contactMobileNumber.placeholder":"+27 73 000 0000",
            "contactLandlineNumber.label":"Landline Number",
            "contactLandlineNumber.placeholder":"+27 21 000 0000",
            "companyName.label":"Company name",
            "companyName.placeholder":"Enter the company name",
            "companyType.label":"Company type",
            "companyType.mortgage-originator":"Mortgage Originator",
            "companyType.transfer-attorney":"Transfer Attorney",
            "companyType.bond-attorney":"Bond Attorney",
            "companyContacts.label":"Contacts",
            "companyContacts.name.label":"Name",
            "companyContacts.name.placeholder":"Full name",
            "companyContacts.emailAddress.label":"Email Address",
            "companyContacts.emailAddress.placeholder":"name@domain.com",
            "companyContacts.mobileNumber.label":"Mobile Number",
            "companyContacts.mobileNumber.placeholder":"+27 73 000 0000",
            "companyContacts.landlineNumber.label":"Landline Number",
            "companyContacts.landlineNumber.placeholder":"+27 21 000 0000",
            "companyDirectors.label":"Names of Directors",

            "companyDirectors.placeholder":" Type the name of all directors of this company",
            "companyRegistrationNumber.label":"Company Registration Number",
            "companyRegistrationNumber.placeholder":"e.g. 2003/00000000",
            "companyBackground.label":"Background & Experience",
            "companyBackground.placeholder":" Tell us more about this company",
            "companyDocuments.sarsLetterOfGoodStanding.label":"SARS Letter of Good Standing",
            "companyDocuments.profile.label":"Company Profile",
            "companyDocuments.nhbrcCertificate.label":"NHBRC Certificate",
            "currentPhaseOfDevelopment.label":"Current Phase of Development",
            "nhbrcCertificate.label":"NHBRC Certificate Number",
            "nhbrcCertificate.placeholder":"",
            "documents.projectLocation.label":"Project Location",
            "documents.sitePlan.label":"Project Siteplan",
            "documents.geotechReport.label":"Geotech Report",
            "documents.engineersCertificate.label":"Engineers Certificate",
            "documents.engineersFloodlineDiagram.label":"Engineers Floodline Diagram",
            "documents.detailedSpecificationAndFinishes.label":"Detailed Specification & finishes",
            "documents.buildingLayoutPlans.label":"Floor Plans of all house types",
            "documents.floorPlans.label":"Building / Complex Layout plans",
            "documents.optionalExtrasAndPricing.label":"Optional extras & pricing",
            "documents.buildingContract.label":"Building Contract",
            "documents.offerToPurchaser.label":"Offer to Purchase",
            "documents.managingAgentsContract.label":"Managing Agents Contract",
            "documents.buildersAllRiskPolicy.label":"Builders All Risk Policy",
            "development.label":"Development",
            "development.placeholder":"Select the development this application is for",
            "development.exampledev1":"Example Development 1",
            "development.exampledev2":"Example Development 2",
            "developmentDescription.label":"Description",
            "developmentDescription.placeholder":"Type a description of this development...",
            "developmentName.label":"Development Name",
            "developmentName.placeholder":"Enter the name of the development",
            "externalDevelopmentHistory.label":"Development History",
            "externalDevelopmentHistory.developmentName.label":"Development Name",
            "externalDevelopmentHistory.developmentName.placeholder":"e.g. Steenberg Estate",
            "externalDevelopmentHistory.state.label":"Project State",
            "externalDevelopmentHistory.state.placeholder":"Select state...",
            "externalDevelopmentHistory.area.label":"Area",
            "externalDevelopmentHistory.area.placeholder":"e.g. Bryanston",
            "externalDevelopmentHistory.totalUnitCount.label":"Total Units",
            "externalDevelopmentHistory.totalUnitCount.placeholder":"",
            "loanTypeRequired.label":"Type of loan required",
            "loanTypeRequired.buildingLoan":"Building Loan (Plot & Plan)",
            "loanTypeRequired.topStructure":"Top Structure",
            "proceedsForLand.label":"How are the proceeds for land paid?",
            "proceedsForLand.paidDirectToSeller":"Paid directly to seller",
            "proceedsForLand.other":"Other",
            "phaseInformation.label":"Phase Information",
            "phaseScope.label":"Phase Scope",
            "phaseScope.phase.label":"Phase",
            "phaseScope.phase.placeholder":"e.g. Phase 1",
            "phaseScope.fullTitleUnits.label":"Unit Count",
            "phaseScope.fullTitleUnits.placeholder":"Number of units",
            "phaseScope.fullTitleDate.label":"Delivery Date",
            "phaseScope.fullTitleDate.placeholder":"e.g. 21 March 2024",
            "phaseScope.sectionTitleUnits.label":"Unit Count",
            "phaseScope.sectionTitleUnits.placeholder":"Number of units",
            "phaseScope.sectionTitleDate.label":"Delivery Date",
            "phaseScope.sectionTitleDate.placeholder":"e.g. 21 March 2024",
            "postalAddress.label":"Postal Address",
            "postalAddress.placeholder":"Enter the postal address...",
            "physicalAddress.label":"Physical Address",
            "physicalAddress.placeholder":"Enter the physcial address...",
            "scopeOfCurrentPhase.label":"Scope of Current Phase",
            "saleContacts.label":"Sale Contacts",
            "saleContact1.label":"Contact 1",
            "saleContact2.label":"Contact 2",
            "attorneys.label":"Attorneys",
            "transferAttorney.label":"Transfer Attorney",
            "bondAttorney.label":"Bond Attorney",
            "site-and-location":"Site & Location",
            "construction":"Construction",
            "contracts":"Contracts",
            "suburb.label": "Select Suburb",
            "supportingDocuments.label":"Supporting Documents",
            "tripartiteAgreementRequirements.label":"Tripartite Agreement Requirements",
            "typeOfUnits.label": "Type of Units",
            "typeOfUnits.placeholder": "Select the type of units you will be selling",
            "typeOfUnits.fullTitle": "Full title",
            "typeOfUnits.sectionalTitle": "Sectional title",
            "totalUnitsInPhase.label": "Total Units in Current Phase",
            "totalUnitsInPhase.placeholder": "0",
            "totalUnitsAllocated.label": "Total Units Allocated to You",
            "totalUnitsAllocated.placeholder": "0",
            "municipality.label": "Municipality",
            "municipality.placeholder": "e.g. Msunduzi",
            "vendorCode.label":"FNB Vendor Code",
            "vendorCode.placeholder":"Paste the vendor code provided to you here",
            "vendorCode.extra":"The vendor code should have been give to you by an FNB representative at the beginning of this application",


        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        saveMissing: true, // send not translated keys to endpoint


        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
