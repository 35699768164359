import React, {FC} from "react";
import {Formik} from "formik";
import {Form, Input} from "formik-antd";
import {Button, Checkbox, Col, Row} from "antd";

interface RegisterFormProps {}
interface RegisterForm {
    firstName: string
    lastName: string
    emailAddress: string
    mobilePhone: string
    password: string
    termsAndAgreements: boolean
}
const RegisterForm: FC<RegisterFormProps> = () => {
    const initialValues: RegisterForm = { firstName: '',lastName:'', emailAddress:'',mobilePhone:'',password:'',termsAndAgreements:false };

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            console.log({ values, actions });
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
        }}
        render={() => (
            <Form layout={'vertical'}>
                <Form.Item name='firstName'  label={"Name"}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Input name='firstName' autoFocus={true} placeholder='First name' />
                        </Col>
                        <Col span={12}>
                            <Input name='lastName' placeholder='Last names' />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item name='emailAddress' label={"Email Address"}>
                    <Input name='emailAddress' placeholder='name@domain.com' />
                </Form.Item>
                <Form.Item name='mobilePhone' label={"Mobile Phone"}>
                    <Input name='mobilePhone' placeholder='+27' />
                </Form.Item>
                <Form.Item name='password' label={"Password"}>
                    <Input.Password  name='password' placeholder='' />
                </Form.Item>
                <Form.Item name='termsAndAgreements' >
                    <Checkbox name='termsAndAgreements'>I agree to the <a href="#">terms & conditions</a> </Checkbox>
                </Form.Item>
                <Button type={"primary"} size={"large"}  block>Register</Button>
            </Form>)}/>
}

export default RegisterForm