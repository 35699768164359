import React, {FC} from "react";
import {FieldArray, Formik} from "formik";
import {Button, Card, Col, Divider, Row, Table} from "antd";
import {
    CheckBoxGroupInput, ContactInput,
    EnumSelect, FormItemContainer, FormSection, getExplodedValues,
    MainForm, RadioGroupInput,
    StringInput, StringInputField
} from "./FormElements";
import {useTranslation} from "react-i18next";
import Icon from "../components/icon/Icon";


interface ApplicationInfoFormProps {
    onSave():any
}
interface ApplicationInfoFormInitialValues {
    loanTypeRequired: string,
    totalUnitsInPhase: number|undefined,
    totalUnitsAllocated: number|undefined,
    phaseScope: Array<any>,
    proceedsForLand: string,
    applicationSubmission: string,
    saleContacts: Array<any>,
    transferAttorney: any,
    bondAttorney: any,

}
const LOAN_TYPES = ["buildingLoan","topStructure"]
const PROCEED_PAYMENT_OPTIONS = ["paidDirectToSeller","other"]
const APPLICATION_SUBMISSION = ["developer","originator"]

const EMPTY_PHASE_ROW = {phase:'',fullTitleUnits:'',fullTitleDate:'',sectionalTitleUnits:'',sectionalTitleDate:''}

const ApplicationInfoForm: FC<ApplicationInfoFormProps> = ({onSave}) => {
    const initialValues: ApplicationInfoFormInitialValues = {
        loanTypeRequired:'',
        totalUnitsInPhase:undefined,
        totalUnitsAllocated:undefined,
        phaseScope:[EMPTY_PHASE_ROW],
        proceedsForLand:'',
        applicationSubmission:'',
        saleContacts:[],
        transferAttorney:undefined,
        bondAttorney:undefined
    };
    const {t} = useTranslation()

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            // console.log({ values, actions });
            onSave()
            actions.setSubmitting(false);
        }}
        render={({values}) => {
            console.log("values",values)
           return <MainForm>
               <FormSection title={"Application"} description={"Tell us more about the type of application"}>

                   <CheckBoxGroupInput code="loanTypeRequired" list={LOAN_TYPES}/>

                   <FormItemContainer code="phaseInformation">
                       <Row gutter={16}>
                           <Col>
                               <StringInput code="totalUnitsInPhase"/>
                           </Col>
                           <Col>
                               <StringInput code="totalUnitsAllocated"/>
                           </Col>
                       </Row>
                   </FormItemContainer>

                   <FormItemContainer code="phaseScope">

                                   <FieldArray name="phaseScope" render={arrayHelpers=>{
                                        let parentCode = "phaseScope";
                                       let valueMap = getExplodedValues(values,parentCode)

                                       return <div>
                                           <div className="ant-table ant-table-small" style={{marginBottom:"1em"}}>
                                           <div className="ant-table-container">
                                               <div className="ant-table-content">
                                                   <table>
                                                       <thead className="ant-table-thead">
                                                       <tr>
                                                           <th className="ant-table-cell">&nbsp;</th>
                                                           <th colSpan={2} className="ant-table-cell">Full Title Units</th>
                                                           <th colSpan={2} className="ant-table-cell">Sectional Title Units</th>
                                                           <th className="ant-table-cell">&nbsp;</th>
                                                       </tr>
                                                       <tr>
                                                           <th className="ant-table-cell">Phase</th>
                                                           <th className="ant-table-cell">Units</th>
                                                           <th className="ant-table-cell">Delivery Date</th>
                                                           <th className="ant-table-cell">Units</th>
                                                           <th className="ant-table-cell">Delivery Date</th>
                                                           <th className="ant-table-cell">&nbsp;</th>
                                                       </tr>
                                                       </thead>
                                                       <tbody className="ant-table-tbody">

                                                       {valueMap && Object.keys(valueMap).length > 0 ? (
                                                           Object.keys(valueMap).map((itemKey, index) => {
                                                               let item = valueMap[itemKey]
                                                               return  <tr key={itemKey} className={`ant-table-row ant-table-row-level-{index}`}>
                                                                   <td className="ant-table-cell">
                                                                       <StringInputField name={`${parentCode}[${index}].phase`} code={`${parentCode}.phase`} />
                                                                   </td>
                                                                   <td className="ant-table-cell">
                                                                       <StringInputField name={`${parentCode}[${index}].fullTitleUnits`} code={`${parentCode}.fullTitleUnits`}/>
                                                                   </td>
                                                                   <td className="ant-table-cell">
                                                                       <StringInputField name={`${parentCode}[${index}].fullTitleDate`} code={`${parentCode}.fullTitleDate`} />
                                                                   </td>
                                                                   <td className="ant-table-cell">
                                                                       <StringInputField name={`${parentCode}[${index}].sectionTitleUnits`} code={`${parentCode}.sectionTitleUnits`} />
                                                                   </td>
                                                                   <td className="ant-table-cell">
                                                                       <StringInputField name={`${parentCode}[${index}].sectionTitleDate`} code={`${parentCode}.sectionTitleDate`} />
                                                                   </td>
                                                                   <td className="ant-table-cell">
                                                                       <a href="#" onClick={() => arrayHelpers.remove(index)}>
                                                                           <Icon icon="bin"/>
                                                                       </a>
                                                                   </td>
                                                               </tr>
                                                           }) ) : ""}

                                                       </tbody>
                                                   </table>

                                               </div>


                                       </div>
                                           </div>
                                           <Button shape="round" onClick={() => arrayHelpers.push(EMPTY_PHASE_ROW)}>
                                               <Icon icon="plus"/> Add
                                           </Button>
                                       </div>
                                   }}/>

                   </FormItemContainer>

               </FormSection>


               <FormSection title={"Tripartite Agreement Requirements"}>
                   <RadioGroupInput code="proceedsForLand" list={PROCEED_PAYMENT_OPTIONS}/>
               </FormSection>

               <FormSection title={"Sales Force"} description={"Tell us more about who will be selling for you"}>
               <EnumSelect code="applicationSubmission" list={APPLICATION_SUBMISSION}/>


                   <Row gutter={16}>
                       <Col span={12}>
                           <FormItemContainer code="saleContact1">
                            <ContactInput code="saleContacts[0]"/>
                           </FormItemContainer>
                       </Col>
                       <Col span={12}>
                           <FormItemContainer code="saleContact2">
                           <ContactInput code="saleContacts[1]"/>
                           </FormItemContainer>
                       </Col>
                   </Row>
               </FormSection>

               <FormSection title={"Attorneys"} description={"Who will be handling the legal processes for the sales"}>
                   <Row gutter={16}>
                       <Col span={12}>
                           <FormItemContainer code="transferAttorney">
                           <ContactInput code="transferAttorney"/>
                           </FormItemContainer>
                       </Col>
                       <Col span={12}>
                           <FormItemContainer code="bondAttorney">
                           <ContactInput code="bondAttorney"/>
                           </FormItemContainer>
                       </Col>
                   </Row>
               </FormSection>



               <Button htmlType={"submit"} type={"primary"} size={"large"}>Next - Documents</Button>
           </MainForm>
        }}/>
}

export default ApplicationInfoForm