import axios from "axios";


let timeout;
let currentValue;

export interface Location {
    value: string
    text: string
}

export const fetchLocation = (value, callback) => {

    currentValue = value;

    function search() {

        axios.get(`https://www.propertyengine.com/reseller/location/searchPublicGlobal?q=${value}`)
            .then(response => {
                // console.log('response',response.data)

                if (currentValue === value) {
                    const data:Array<Location> = [];
                    response.data.forEach(r => {
                        data.push({
                            value: r.label,
                            text: r.label,
                        });
                    });
                    //   console.log('data',data)
                    callback(data);
                }
            });
    }

    timeout = setTimeout(search, 300);
}