import React, {FC} from 'react';
import {Button, Col, Row} from "antd";
import styled from "styled-components";






const MainPageContainer = styled.div`
    padding: 2em;
`
const MainPageHeader = styled.div`
    h1{
      font-size: 2em;
    }
`
const MainPageHeaderActionsContainer = styled.div`
    width:100%;
  overflow: hidden;
`
const MainPageHeaderActions = styled.div`
    float:right;
`
const MainPageContent = styled.div`
    
`
interface MainPageProps {
    title: string
    pageActions?: React.ReactNode
}
const MainPageTemplate: FC<MainPageProps> = ({title,pageActions,children}) => {

    return <MainPageContainer >
        <MainPageHeader>
            <Row>
                <Col span={18}>
                    <h1>{title}</h1>
                </Col>
                <Col span={6}>

                    {pageActions?
                        <MainPageHeaderActionsContainer><MainPageHeaderActions>{pageActions}</MainPageHeaderActions></MainPageHeaderActionsContainer>
                        :''}
                </Col>
            </Row>
        </MainPageHeader>

        <MainPageContent>
            {children}
        </MainPageContent>
    </MainPageContainer>
}

export default MainPageTemplate