import React, {FC} from "react";
import MainPageTemplate from "../pageTemplates/MainPageTemplate";
import DevelopmentForm from "../forms/DevelopmentForm";
import ApplicationSteps from "../components/ApplicationSteps";
import DeveloperForm from "../forms/DeveloperForm";
import ApplicationInfoForm from "../forms/ApplicationInfoForm";
import DocumentationForm from "../forms/DocumentationForm";
import ApplicationSummary from "../components/ApplicationSummary";
import styled from "styled-components";

export const StyledApplicationContainer = styled.div`
    background: white;
    padding: 2em;
`
export const ButtonBar = styled.div`

`

interface ApplicationDevelopmentProps {
    step: number,
    onSave(): void
}
const Application: FC<ApplicationDevelopmentProps> = ({step=0, onSave}) => {



    let stepContent:any = undefined

    if(step===0){
        stepContent = <DevelopmentForm onSave={onSave} />
    }else if(step===1){
        stepContent = <DeveloperForm title={"Developer"} onSave={onSave} />
    }else if(step===2){
        stepContent = <DeveloperForm title={"Builder"} onSave={onSave} />
    }else if(step===3){
        stepContent = <ApplicationInfoForm onSave={onSave} />
    }else if(step===4){
        stepContent = <DocumentationForm onSave={onSave} />
    }else if(step===5){
        stepContent = <ApplicationSummary/>
    }


    return <MainPageTemplate title={"New Application"}  >
        <ApplicationSteps activeStep={step}/>
        <StyledApplicationContainer>
            {stepContent}
        </StyledApplicationContainer>
    </MainPageTemplate>
}

export default Application