import React, {FC} from "react";
import SingleUsePageTemplate from "../pageTemplates/SingleUsePageTemplate";
import LoginForm from "../forms/LoginForm";

interface LoginProps {}


const Login: FC<LoginProps> = () => {

    return <SingleUsePageTemplate title="Login" columns={1} primaryBrandLogoUrl={"../images/ds-logo.png"} >
                <h2>Sign in </h2>
                <LoginForm/>
            </SingleUsePageTemplate>
}

export default Login