import React, {FC} from "react";
import {Formik} from "formik";
import {Button, Col, Row} from "antd";
import {
    EnumSelect,
    FormFieldArray, FormItemContainer, FormSection,
    MainForm, RadioGroupInput,
    StringInput,
    TextAreaInput, UploadInputRow
} from "./FormElements";
import {useTranslation} from "react-i18next";

interface DocumentationFormProps {
    onSave():any
}
interface DocumentationFormInitialValues {
    documents: Array<any>,
}

export const DOCUMENT_CATEGORY_SITE_AND_LOCATION = "site-and-location"
export const DOCUMENT_CATEGORY_CONSTRUCTION = "construction"
export const DOCUMENT_CATEGORY_CONTRACTS = "contracts"
export const DOCUMENT_CATEGORIES = [DOCUMENT_CATEGORY_SITE_AND_LOCATION,DOCUMENT_CATEGORY_CONSTRUCTION,DOCUMENT_CATEGORY_CONTRACTS]
interface Document {
    id: string
    position: number
    label: string
    category: string

}

export const DOCUMENTS: Array<Document> = [
    {id:"projectLocation",position:1,label:"Project Location", category:DOCUMENT_CATEGORY_SITE_AND_LOCATION},
    {id:"sitePlan",position:2,label:"Site Plan", category:DOCUMENT_CATEGORY_SITE_AND_LOCATION},
    {id:"geotechReport",position:3,label:"Geotech Report", category:DOCUMENT_CATEGORY_SITE_AND_LOCATION},
    {id:"engineersCertificate",position:4,label:"Professional Engineers Certificate", category:DOCUMENT_CATEGORY_SITE_AND_LOCATION},
    {id:"engineersFloodlineDiagram",position:5,label:"Professional Engineers Floodline Diagram", category:DOCUMENT_CATEGORY_SITE_AND_LOCATION},

    {id:"floorPlans",position:1,label:"Building / Complex Layout plans", category:DOCUMENT_CATEGORY_CONSTRUCTION},
    {id:"buildingLayoutPlans",position:2,label:"Floor Plans of all house types", category:DOCUMENT_CATEGORY_CONSTRUCTION},
    {id:"detailedSpecificationAndFinishes",position:3,label:"Detailed Specification & finishes", category:DOCUMENT_CATEGORY_CONSTRUCTION},
    {id:"optionalExtrasAndPricing",position:4,label:"Optional extras & pricing", category:DOCUMENT_CATEGORY_CONSTRUCTION},

    {id:"buildingContract",position:1,label:"Building Contract", category:DOCUMENT_CATEGORY_CONTRACTS},
    {id:"offerToPurchaser",position:2,label:"Offer to Purchase", category:DOCUMENT_CATEGORY_CONTRACTS},
    {id:"managingAgentsContract",position:3,label:"Managing Agents Contract", category:DOCUMENT_CATEGORY_CONTRACTS},
    {id:"buildersAllRiskPolicy",position:4,label:"Builders All Risk Policy", category:DOCUMENT_CATEGORY_CONTRACTS},

]

const DocumentationForm: FC<DocumentationFormProps> = ({onSave}) => {
    const initialValues: DocumentationFormInitialValues = {
        documents:[],
    };
    const {t} = useTranslation()

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            // console.log({ values, actions });
            onSave()
            actions.setSubmitting(false);
        }}
        render={({values}) => {
           return <MainForm>

               {DOCUMENT_CATEGORIES.map((documentCategory)=>{

                   const docsInCategory = DOCUMENTS.filter((doc)=>(doc.category===documentCategory))
                   docsInCategory.sort((doc)=>doc.position)

                    return  <FormSection title={t(documentCategory)}>
                        <Row gutter={24}>
                            {docsInCategory.map((doc,index)=>{
                                return <UploadInputRow code={`documents.${doc.id}`}/>
                            })}
                        </Row>
                    </FormSection>
               })}

               <Button htmlType={"submit"} type={"primary"} size={"large"}>Next - Review</Button>
           </MainForm>
        }}/>
}

export default DocumentationForm