import React, {FC} from "react";
import {Button, Card} from "antd";
import ContactPickerModal from "../modals/ContactPickerModal";


interface Contact {
    id: string
    name: string
    emailAddress?: string
    landlineNumber?: string
    mobileNumber?: string
}
interface Company{
    id: string
    name: string
}
interface ContactProps {
    company?: Company
    contact: Contact
    handleChange?: {(Company,Contact) : void}
}


const ContactCard: FC<ContactProps> = ({company, contact, handleChange})=>{

    let change
    if(handleChange){
        change = <ContactPickerModal onSelect={handleChange}
                                     trigger={(onClick) => {
                                         return <Button type="link" shape={"round"} onClick={onClick}>Change</Button>
                                     }}/>
    }


    if(company){
        return <Card title={company.name} bordered={true}  extra={change}>
            {contact.name}<br/>
            <a href={`mailto:${contact.emailAddress}`}>{contact.emailAddress}</a><br/>
            <a href={`tel:${contact.landlineNumber}`}>{contact.landlineNumber}</a><br/>
            <a href={`tel:${contact.mobileNumber}`}>{contact.mobileNumber}</a><br/>
        </Card>
    }
    return <Card title={contact.name} bordered={true}  extra={change}>
        <a href={`mailto:${contact.emailAddress}`}>{contact.emailAddress}</a><br/>
        <a href={`tel:${contact.landlineNumber}`}>{contact.landlineNumber}</a><br/>
        <a href={`tel:${contact.mobileNumber}`}>{contact.mobileNumber}</a><br/>
    </Card>
}
export default ContactCard