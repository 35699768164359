import React, {FC} from "react";
import MainPageTemplate from "../pageTemplates/MainPageTemplate";
import {Button} from "antd";

import {Application} from "../data/Application";
import ListItem from "../components/ListItem";
import {MetaData, SimpleDate} from "../components/Display";
import styled from "styled-components";
import  { Breakpoint } from 'react-socks';


const StyledContent = styled.div`
  width:100%;
  min-width: 300px ;
  .contentRow{
    display: flex;
    flex-wrap: nowrap;
    align-items: top;
    justify-content: start;
    margin-bottom: 0.5em;
  }
  .contentRow--single{
    justify-content: start;
    align-items: center;

    min-width: 200px;
    .title{
      min-width: 200px;
    }
  }
  .logo{
    width: 80px;
  }
  .title{
    font-size: 1.2em;
    line-height: 40px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    padding: 0 1em;
  }
  .type{
    line-height: 40px;
    vertical-align: middle;
    display: inline-block;
    float: right;
  }
  .description{
    font-style: italic;
    line-height: 40px;
    vertical-align: middle;
    display: inline-block;
  }
`
interface ApplicationListItemProps {
    application: Application
}
const ApplicationListItem: FC<ApplicationListItemProps> = ({application}) =>{
    return <StyledContent>
                <Breakpoint medium down>
                    <div className="contentRow" >
                        <div className="logo">
                            <img src={application.bankLogo} width={80}/>
                        </div>
                        <span className="title">{application.developmentName}</span>
                        <span className="type">{application.type}</span>
                    </div>

                    <div className="contentRow contentRow--meta">
                        <MetaData label={"Type"} value={application.type}/>
                        <MetaData label={"Created"} value={<SimpleDate date={application.dateCreated} format={"DD/MM/YYYY"}/>}/>
                        <MetaData label={"Submitted"} value={<SimpleDate date={application.dateSubmitted} format={"DD/MM/YYYY"}/>}/>
                        <MetaData label={"Status"} value={application.status}/>
                    </div>
                </Breakpoint>
                <Breakpoint medium up>
                    <div className="contentRow contentRow--single">
                        <div className="logo">
                            <img src={application.bankLogo} width={80}/>
                        </div>
                        <span className="title">{application.developmentName}</span>
                        <MetaData label={"Type"} value={application.type}/>

                        <MetaData label={"Created"} value={<SimpleDate date={application.dateCreated} format={"DD/MM/YYYY"}/>}/>
                        <MetaData label={"Submitted"} value={<SimpleDate date={application.dateSubmitted} format={"DD/MM/YYYY"}/>}/>
                        <MetaData label={"Status"} value={application.status}/>

                    </div>
                </Breakpoint>
           </StyledContent>
}

interface AccountProps {
    applications: Array<Application>
}
const AccountDashboard: FC<AccountProps> = ({applications=[]}) => {

    let actions = <Button type={"primary"} size={"large"} ><Breakpoint medium down>New</Breakpoint><Breakpoint medium up>New Application</Breakpoint></Button>
    return <MainPageTemplate title={"Dashboard"} pageActions={actions} >
        {applications.map((application,key)=>{
            return <ListItem key={key}
                             render={<ApplicationListItem application={application}/>}
                             actions={<Button shape={"round"}>View</Button>}
            />
        })}
    </MainPageTemplate>
}

export default AccountDashboard