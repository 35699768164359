import React, {FC} from "react";
import MainPageTemplate from "../pageTemplates/MainPageTemplate";
import ApplicationNewForm from "../forms/ApplicationNewForm";
import ApplicationExistingForm from "../forms/ApplicationExistingForm";
import {StyledApplicationContainer} from "./Application";

interface ApplicationStartProps {
    mode: string
}
const ApplicationStart: FC<ApplicationStartProps> = ({mode}) => {



    return <MainPageTemplate title={"New Application"}  >
        <StyledApplicationContainer>
        {mode==="existing"?<ApplicationExistingForm/>:<ApplicationNewForm/>}
        </StyledApplicationContainer>
    </MainPageTemplate>
}

export default ApplicationStart