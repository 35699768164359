import React, {FC} from "react";
import SingleUsePageTemplate, {InfoWindow} from "../pageTemplates/SingleUsePageTemplate";
import {Col, Row} from "antd";
import RegisterForm from "../forms/RegisterForm";
import LoginForm from "../forms/LoginForm";
import ConnectForm from "../forms/ConnectForm";

interface InvitationPageProps {
    mode:string
}


const InvitationPage: FC<InvitationPageProps> = ({mode='register'}) => {

    return <SingleUsePageTemplate title="Register"
                                  secondaryBrandLogoUrl={"../images/ds-logo.png"}
                                  primaryBrandLogoUrl={"https://upload.wikimedia.org/wikipedia/en/thumb/8/80/First_National_Bank_Logo.svg/1200px-First_National_Bank_Logo.svg.png"}>
        <Row gutter={16}>
            <Col span={10}>
                {mode==='register'?<InfoWindow
                    title="You've been invited to start your end user finance application for Blue Raven Estate"
                    description="Please first create an account so that you can upload and submit the information digitally"
                />:''}
                {mode==='login'?<InfoWindow
                    title="You've been invited to start your end user finance application for Blue Raven Estate"
                    description="You already have an account, please login to connect so that you can upload and submit the information digitally"
                />:''}
                {mode==='connect'?<InfoWindow
                    title="Hi Francois! You've been invited to start your end user finance application for Blue Raven Estate"
                    description=""
                />:''}
            </Col>
            <Col span={14}>
                {mode==='register'?<RegisterForm/>:''}
                {mode==='login'?<LoginForm primaryActionText={'Continue'}/>:''}
                {mode==='connect'?<ConnectForm/>:''}
            </Col>

        </Row>
    </SingleUsePageTemplate>
}

export default InvitationPage