import React from 'react';
import PropTypes from 'prop-types';
import iconSet from './selection.json';
import AntIcon from '@ant-design/icons';


const Icon = (props) => {

    const styles = {
        svg: {
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        path: {
            fill: props.color
        }
    };


    const find = function find(iconEl) {
        return iconEl.properties.name.split(', ').includes(props.icon);
    };
    const currentIcon = iconSet.icons.find(find);

    if(currentIcon){
        const renderPath = function renderPath(iconObj) {
            return function (path, index) {
                const attrs = {
                    style: styles.path,
                    key: index,
                    d: path
                };
                return React.createElement("path", attrs);
            };
        };

        const svgPath = () => (
            <svg viewBox="0 0 1024 1024" width={props.size} height={props.size} fill="currentColor">
                {currentIcon.icon.paths.map(renderPath(currentIcon.icon))}
            </svg>
        );

        return <AntIcon component={svgPath} width={props.size}  height={props.size} {...props}/>;
    }else{
        return <i>?</i>
    }


};

Icon.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string.isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Icon.defaultProps = {
    size: '1em',
};

export default Icon;
