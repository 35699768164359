import {Button, Divider, List, Modal, PageHeader} from "antd";
import {Radio} from "formik-antd";
import React, {FC, useState} from "react";
import styled from "styled-components";
import Search from "antd/es/input/Search";
import ContactCreateForm from "../forms/ContactCreateForm";

interface Company {
    id: string,
    name: string,
    address: string
}
interface Contact {
    id: string,
    name: string,
    emailAddress: string
}
const MODE_SEARCH = "search"
const MODE_CONTACT_SELECT = "contact-select"
const MODE_NEW_COMPANY = "new-company"

const StyledRadioButton = styled(Radio.Button)`
    margin-bottom: 1em;
    height: 40px;
    width: 50%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
`
const StyledPageHeader = styled(PageHeader)`
    padding: 0;
`

interface ContactPickerProps {
    onSelect?: {(Company,Contact): void}
    trigger(any) : React.ReactNode
}

const ContactPickerModal: FC<ContactPickerProps> = ({onSelect,trigger})=>{

    const [mode, setMode] = useState(MODE_SEARCH)

    const [showModal,setShowModal] = useState(false)
    const [company, setCompany] = useState<Company|null>()
    const [contact, setContact] = useState<Contact|null>()
    const [companyList,setCompanyList] = useState<Array<Company>>([{id:"A",name:'ACME Company',address:"17 Dreyersdal Road, Bergvliet, 7945"},
        {id:"B", name:'Cleaver Consulting',address:"12 Cape Town Center 7945"}])
    const [contactList,setContactList] = useState([
        {name:'Simon Comley',emailAddress:"simon@cleaver.co.za"},
        {name:'Simon Comley',emailAddress:"simon@propertyengine.com"}
    ])

    const onContactSave = () =>{

        setShowModal(false)
    }
    const onContactSelect = (contact) =>{

        if(onSelect){
            onSelect(company,contact)
        }

        setShowModal(false)
    }

    const onSearch = ()=>{

    }

    let modalContent: React.ReactNode = undefined
    if(showModal){
        if(mode === MODE_SEARCH && !company){
            modalContent = <>
                <Search
                    placeholder="Search for company..."
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={onSearch}
                />
                <Divider style={{marginBottom:0}}/>
                <List
                    dataSource={companyList}

                    renderItem={item => (
                        <List.Item
                            actions={[<Button shape={"round"} key="select" onClick={()=>{setCompany(item);setMode(MODE_CONTACT_SELECT)}}>Select</Button>]}
                        >
                            <List.Item.Meta
                                title={item.name}
                                description={item.address}
                            />
                        </List.Item>
                    )}
                />
            </>
        }else if(mode===MODE_NEW_COMPANY){
            modalContent = <ContactCreateForm onSave={onContactSave}/>
        }else if(mode===MODE_CONTACT_SELECT){
            modalContent = <>
                {company?<StyledPageHeader
                    onBack={() => {setCompany(null);setMode(MODE_SEARCH)}}
                    title={`Select contact from ${company.name}`}
                />:''}
                <List
                    dataSource={contactList}
                    renderItem={item => (
                        <List.Item actions={[<Button shape={"round"} key="select" onClick={()=>onContactSelect(item)}>Select</Button>]}>
                            <List.Item.Meta title={item.name} description={item.emailAddress} />
                        </List.Item>
                    )}
                />
            </>
        }
    }

    return <div>
        {trigger(()=>setShowModal(true))}

        <Modal width={800} visible={showModal} closable={false} onCancel={()=>setShowModal(false)}
               footer={null}
        >
            {!company?<Radio.Group defaultValue="search" size="large" name={"bb"} style={{width:"100%", marginBottom:"1em"}} onChange={(e)=>setMode(e.target.value)}>
                <StyledRadioButton value={MODE_SEARCH}>Search Existing</StyledRadioButton>
                <StyledRadioButton value={MODE_NEW_COMPANY}>Create New</StyledRadioButton>
            </Radio.Group>:''}
            {modalContent}
        </Modal>
    </div>

}

export default ContactPickerModal