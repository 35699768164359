import moment from "moment";
import React, {FC} from "react"
import styled from "styled-components";

interface MetaDataProps {
    label?: string
    value: any
}
const StyledMetaData = styled.span`
    display: inline-block;
    position: relative;
    margin-right: 1em;
    .metaLabel{
      color: gray;
      position: absolute;
      top: -0.5em;
      font-size: 0.8em;
    }
    .metaValue{
      padding-top: 0.7em;
      line-height: 1;
      display: inline-block;
    }
`
export const MetaData: FC<MetaDataProps> = ({label,value}) =>{
    return <StyledMetaData>
        <span className="metaLabel">{label}</span>
        <span className="metaValue">{value}</span>
    </StyledMetaData>
}

const SpanWithLineBreaks = styled.span`
white-space: pre-line;

`
export const FormatWithLineBreaks = ({children}) =>{
    return <SpanWithLineBreaks>{children}</SpanWithLineBreaks>
}


export const SimpleDate = ({date,format}) => {
    if(!date)
        return <span>-</span>
    return  <span>{moment(date).format(format)}</span>
};

export const DATE_FORMAT_DATE_ONLY = "DD-MM-yyyy"
SimpleDate.defaultProps = {
    format: "LLL"
}


export const BirthDate = ({date}) => {
    return  <span>{moment(date).format("yyyy-MM-DD")}</span>
}
export const TableDate = ({date}) => {
    return  <span>{moment(date).format("yyyy-MM-DD HH:mm")}</span>
};