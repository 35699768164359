import React, {FC} from "react";
import {Formik} from "formik";
import {Button} from "antd";
import {
    EnumSelect,
    FormSection,
    LocationSearch,
    MainForm,
    RadioButtons,
    StringInput,
    TextAreaInput
} from "./FormElements";

interface DevelopmentFormProps {
    onSave():any
}
interface DevelopmentFormInitialValues {
    developmentName: string,
    suburb: string,
    municipality: string,
    typeOfUnits: string,
    developmentDescription: string,
}
const DevelopmentForm: FC<DevelopmentFormProps> = ({onSave}) => {
    const initialValues: DevelopmentFormInitialValues = { developmentName:'',suburb:'',municipality:'',typeOfUnits:'',developmentDescription:''  };

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            console.log({ values, actions });
            onSave()
            actions.setSubmitting(false);
        }}
        render={() => (
           <MainForm>
               <FormSection title={"Development"} description={"Please let us know the following about the development"}>

                    <StringInput code="developmentName" />
                    <LocationSearch code="suburb"  />
                    <StringInput code="municipality" />

                    <EnumSelect code="typeOfUnits" list={['fullTitle',"sectionalTitle"]}/>
                    <TextAreaInput code="developmentDescription" />

                    <Button htmlType={"submit"} type={"primary"} size={"large"} >Next - Developer Information</Button>
               </FormSection>
           </MainForm>)}/>
}

export default DevelopmentForm