import React, {FC} from "react";
import MainPageTemplate from "../pageTemplates/MainPageTemplate";
import {Button, Table,} from "antd";
import { Company, Contact} from "../data/Application";

import  { Breakpoint } from 'react-socks';

interface ContactsProps {
    contacts: Array<Contact|Company>
}
const Contacts: FC<ContactsProps> = ({contacts=[]}) => {

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: text => <a href="#">{text}</a>,
        },

        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Company',
            dataIndex: 'companyName',
            key: 'company',
        },

        {
            title: 'Landline',
            dataIndex: 'landlinePhone',
            key: 'landlinePhone',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobilePhone',
            key: 'mobilePhone',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Button style={{float:"right"}} shape={"round"}>View</Button>
            ),
        },
    ];





    let actions = <Button type={"primary"} size={"large"} ><Breakpoint medium down>New</Breakpoint><Breakpoint medium up>New Contact</Breakpoint></Button>
    return <MainPageTemplate title={"Contacts"} pageActions={actions} >

        <Table pagination={false} columns={columns} dataSource={contacts} />
    </MainPageTemplate>
}

export default Contacts