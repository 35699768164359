import React, {FC, useState} from "react";
import {Steps} from "antd";
import styled from "styled-components";

const { Step } = Steps;

const StepsWrapper = styled.div`
  padding: 1em 2em;
    background: #ffffff;
  border-radius: 5px;
  margin-bottom: 2em;
`


interface ApplicationStepsProps  {
    activeStep: number
}

const ApplicationSteps: FC<ApplicationStepsProps> = ({activeStep})=>{

    return <StepsWrapper>

        <Steps size="small" current={activeStep} >
        <Step title="Development" />
        <Step  title="Developer" />
        <Step  title="Builder" />
        <Step title="Application" />
        <Step title="Documents" />
        <Step title="Review" />
    </Steps>
    </StepsWrapper>

}

export default ApplicationSteps