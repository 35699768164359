import React, {FC} from "react";
import {Alert, Button} from "antd";
import styled from "styled-components";

const ConnectButton = styled(Button)`
    margin-bottom: 1em;
`
interface ConnectFormProps {}

const ConnectForm: FC<ConnectFormProps> = () => {

    return  <div>
        <Alert message={`Your email address is connected to more than one account, please select the one that the application for Blue Raven Estate should be connected to.`} style={{marginBottom:15}}/>
        <ConnectButton size={"large"} type={"primary"} block>Cosmopolitan Developers </ConnectButton>
        <ConnectButton size={"large"}  type={"primary"} block>OOBA </ConnectButton>
        <ConnectButton size={"large"}   block>Create new account</ConnectButton>
    </div>
}

export default ConnectForm