import React, {FC} from "react";
import {Formik} from "formik";
import {Button, Col, Row} from "antd";
import {
    EnumSelect,
    FormFieldArray, FormItemContainer, FormSection,
    MainForm,
    StringInput,
    TextAreaInput, UploadInputRow
} from "./FormElements";
import {useTranslation} from "react-i18next";

interface DeveloperFormProps {
    title:string
    onSave():any
}
interface DeveloperFormInitialValues {
    companyName: string,
    companyRegistrationNumber: string,
    companyDirectors: string,
    nhbrcCertificate: string,
    companyBackground: string,
    companyContacts: Array<any>,
}
const DeveloperForm: FC<DeveloperFormProps> = ({title, onSave}) => {
    const initialValues: DeveloperFormInitialValues = {
        companyName:'',
        companyRegistrationNumber:'',
        companyDirectors:'',
        nhbrcCertificate:'',
        companyBackground:'',
        companyContacts:[
            {name:'',emailAddress:'',landlineNumber:'',mobileNumber:''},
            {name:'',emailAddress:'',landlineNumber:'',mobileNumber:''}
        ],
    };
    const {t} = useTranslation()

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            // console.log({ values, actions });
            onSave()
            actions.setSubmitting(false);
        }}
        render={({values}) => {
           return <MainForm>
               <FormSection title={title} description={"Please let us know the following about the "+title+" of this development"}>

               <StringInput code="companyName"/>
               <StringInput code="companyRegistrationNumber"/>
               <TextAreaInput code="companyDirectors"/>
               <StringInput code="nhbrcCertificate"/>
               <TextAreaInput code="companyBackground"/>
               <FormFieldArray code="companyContacts"
                               values={values}
                               emptyData={{name: ''}}
                               renderItem={(parentCode, loopCode, item, index) => {
                                   return <div style={{width: "100%"}}>
                                       <Row gutter={16}>
                                           <Col flex="1">

                                               <StringInput name={`${parentCode}.name.${index}`}
                                                            code={`${parentCode}.name`}
                                                            hideLabel={index > 0}/>
                                           </Col>
                                           <Col flex="1">
                                               <StringInput name={`${parentCode}.emailAddress.${index}`}
                                                            code={`${parentCode}.emailAddress`}
                                                            hideLabel={index > 0}/>
                                           </Col>
                                           <Col flex="1">
                                               <StringInput name={`${parentCode}.mobileNumber.${index}`}
                                                            code={`${parentCode}.mobileNumber`}
                                                            hideLabel={index > 0}/>
                                           </Col>
                                           <Col flex="1">
                                               <StringInput name={`${parentCode}.landlineNumber.${index}`}
                                                            code={`${parentCode}.landlineNumber`}
                                                            hideLabel={index > 0}/>
                                           </Col>
                                       </Row>
                                   </div>
                               }}/>


               <FormFieldArray code="externalDevelopmentHistory"
                               values={values}
                               emptyData={{name: ''}}
                               renderItem={(parentCode, loopCode, item, index) => {
                                   return <div style={{width: "100%"}}>
                                       <Row gutter={16}>
                                           <Col flex="1">

                                               <StringInput name={`${parentCode}.developmentName.${index}`}
                                                            code={`${parentCode}.developmentName`}
                                                            hideLabel={index > 0}/>
                                           </Col>
                                           <Col flex="1">

                                               <EnumSelect name={`${parentCode}.state.${index}`}
                                                           list={['in-progress','complete']}
                                                           code={`${parentCode}.state`}
                                                           hideLabel={index > 0}/>
                                           </Col>
                                           <Col flex="1">
                                               <StringInput name={`${parentCode}.area.${index}`}
                                                            code={`${parentCode}.area`}
                                                            hideLabel={index > 0}/>
                                           </Col>
                                           <Col flex="1">
                                               <StringInput name={`${parentCode}.totalUnitCount.${index}`}
                                                            code={`${parentCode}.totalUnitCount`}
                                                            hideLabel={index > 0}/>
                                           </Col>
                                       </Row>
                                   </div>
                               }}/>
                        <FormItemContainer code={'supportingDocuments'}>
                            <Row gutter={24}>
                                    <UploadInputRow code={'companyDocuments.sarsLetterOfGoodStanding'}/>
                                    <UploadInputRow code={'companyDocuments.profile'}/>
                                    <UploadInputRow code={'companyDocuments.nhbrcCertificate'}/>
                            </Row>
                        </FormItemContainer>


               <Button htmlType={"submit"} type={"primary"} size={"large"}>Next - Application</Button>
               </FormSection>
           </MainForm>
        }}/>
}

export default DeveloperForm