import React, {FC} from "react";
import {Formik} from "formik";
import {Button} from "antd";
import { MainForm, RadioButtons, StringInput} from "./FormElements";

interface ApplicationNewFormProps {}
interface ApplicationNewInitialValues {

}
const ApplicationNewForm: FC<ApplicationNewFormProps> = () => {
    const initialValues: ApplicationNewInitialValues = {  };

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            console.log({ values, actions });
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
        }}
        render={() => (
           <MainForm>
                <StringInput code="developmentName" />
                <StringInput code="applicationReason" />
                <RadioButtons code="bank" list={["fnb"]}/>
               <StringInput code="vendorCode" />

                <Button type={"primary"} size={"large"} >Start Application</Button>
           </MainForm>)}/>
}

export default ApplicationNewForm