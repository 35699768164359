import React, {FC} from "react";
import MainPageTemplate from "../pageTemplates/MainPageTemplate";
import {Button,  Table} from "antd";
import {Development} from "../data/Application";

import  { Breakpoint } from 'react-socks';

interface DevelopmentsProps {
    developments: Array<Development>
}
const Developments: FC<DevelopmentsProps> = ({developments=[]}) => {

    const columns = [
        {
            title: 'Development Name',
            dataIndex: 'name',
            key: 'name',
            render: text => <a  href="#">{text}</a>,
        },

        {
            title: 'Unit Types',
            dataIndex: 'typeOfUnits',
            key: 'typeOfUnits',
        },
        {
            title: 'Suburb',
            dataIndex: 'suburb',
            key: 'suburb',
        },
        {
            title: 'Municipality',
            dataIndex: 'municipality',
            key: 'municipality',
        },
        {
            title: 'Applications',
            dataIndex: 'applicationCount',
            key: 'applicationCount',
            render: text => <a href="#">{text} Applications</a>,
        },

        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Button style={{float:"right"}} shape={"round"}>View</Button>
            ),
        },
    ];





    let actions = <Button type={"primary"} size={"large"} ><Breakpoint medium down>New</Breakpoint><Breakpoint medium up>New Development</Breakpoint></Button>
    return <MainPageTemplate title={"Developments"} pageActions={actions} >

        <Table  pagination={false} columns={columns} dataSource={developments} />
    </MainPageTemplate>
}

export default Developments