import React, {FC} from "react";
import {Formik} from "formik";
import {Form, Input} from "formik-antd";
import {Button} from "antd";

interface ResetPasswordFormProps {
}
interface ResetPasswordFormInitialValues {
    password1: string
    password2: string
}
const ResetPasswordForm: FC<ResetPasswordFormProps> = () => {
    const initialValues: ResetPasswordFormInitialValues = { password1:'', password2:''};

    return  <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
            console.log({ values, actions });
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
        }}
        render={() => (
            <Form layout={'vertical'}>
                <Form.Item name='password1' label={"Your new password"}>
                    <Input.Password name='password1'  />
                </Form.Item>
                <Form.Item name='password2' label={"Re-type your new password"}>
                    <Input.Password name='password2'  />
                </Form.Item>

                <Button type={"primary"} size={"large"}  block>Reset My Password</Button>

            </Form>)}/>
}

export default ResetPasswordForm